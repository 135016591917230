*:focus {
  outline: none;
}

html {
  scroll-behavior: smooth !important;
}

body.light-mode {
  background-color: #e8eaf6;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body.light-mode h1 {
  color: #9ea9ec;
  font-family: 'Cereal', sans-serif;
  font-size: 22px;
  font-weight: 900;
}

body.light-mode h2 {
  font-family: 'Cereal', sans-serif;
  font-size: 18px !important;
  font-weight: 400;
}

body.light-mode h3 {
  font-family: 'Cereal', sans-serif;
  font-size: 14px !important;
  font-weight: 400;
}

body.light-mode h4 {
  font-family: 'Cereal', sans-serif;
  font-size: 13px !important;
  font-weight: 900;
}

body.light-mode h5 {
  font-family: 'Cereal', sans-serif;
  font-size: 12px !important;
  font-weight: 900;
}

body.light-mode h6 {
  font-family: 'Cereal', sans-serif;
  font-size: 11px !important;
  font-weight: 600;
}

body.light-mode .App {
  min-height: 100vh;
}

body.light-mode .switch-wrapper {
  position: relative;
}

body.light-mode .switch-wrapper > div {
  position: absolute;
  transition: all 0.25s ease-in-out;
  width: 100%;
}

body.light-mode .Navbar {
  display: flex;
  flex-direction: row;
  height: 10rem;
  justify-content: space-between;
  padding-bottom: 1rem;
}

body.light-mode .Navbar > * {
  align-self: center;
}

body.light-mode .Navbar a {
  color: inherit;
  text-decoration: none;
}

body.light-mode .Navbar .navbar-left {
  color: #9ea9ec;
  cursor: pointer;
  font-family: 'Cereal', sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .Navbar .navbar-middle {
  color: #9ea9ec;
  font-family: 'Cereal', sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
}

body.light-mode .Navbar .navbar-middle span {
  color: #9ea9ec;
  font-weight: 600;
}

body.light-mode .Navbar .navbar-right {
  color: #9ea9ec;
  cursor: pointer;
  font-family: 'Cereal', sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-right: -2px;
  transition: all 1s ease-in-out;
}

@media (min-width: 769px) {
  body.light-mode .Navbar .navbar-right {
    width: 100%;
  }
}

@media (max-width: 769px) {
  body.light-mode .Navbar .navbar-right {
    width: 2rem;
  }
}

body.light-mode .Navbar .expand {
  background: #4051b5;
  color: #9ea9ec;
  display: flex;
  flex-direction: column;
  font-family: 'Cereal', sans-serif;
  height: 100vh;
  position: absolute;
  transform: none;
  z-index: 9999;
}

body.light-mode .Navbar .expand .love a {
  background-image: linear-gradient(#4051b5, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #fff;
  font-weight: 900;
  transition: background-size 0.3s;
}

body.light-mode .Navbar .expand .love a:hover {
  background-size: 100% 2px;
}

body.light-mode .Navbar .expand .love:hover {
  background-color: #4051b5;
  margin-left: 0;
  width: 60%;
}

body.light-mode .Navbar .expand > * {
  padding: 1.5rem;
  padding-right: 1rem;
  transition: all 0.15s ease-in-out;
}

body.light-mode .Navbar .expand > *:hover {
  background-color: #5160bc;
  border-radius: 20px;
  padding-right: 1rem;
  transition: all 0.2s ease-in-out;
}

body.light-mode .Navbar .expand > * span.focused,
body.light-mode .Navbar .expand > * span.hover {
  background: #9ea9ec;
  border-radius: 10px;
  color: #fff;
  padding: 0.75rem;
  transition: background-color 0.2s;
}

body.light-mode .Navbar .expand .expand-bottom {
  align-self: flex-start;
  border: 0;
}

body.light-mode .Navbar .expand .expand-bottom:hover {
  background: none;
}

body.light-mode .Navbar .expand .expand-top {
  height: 0;
}

body.light-mode .Navbar .expand .expand-top:hover {
  background: #4051b5;
}

body.light-mode .love:hover {
  background: #4051b5;
  border-radius: 15px;
  margin-left: 1vw;
  width: 50%;
}

@media (max-width: 769px) {
  body.light-mode .body {
    text-align: center;
  }

  body.light-mode .content-area {
    display: inline-block;
    margin-top: 0.5rem;
    width: 95%;
  }

  body.light-mode .Navbar {
    background-color: #4051b5;
    height: 3rem;
  }

  body.light-mode .Navbar .navbar-right {
    color: #9ea9ec;
    height: 30px;
    padding-right: 15px;
    z-index: 99999;
  }

  body.light-mode .Navbar .navbar-middle {
    background-color: none;
    z-index: 999999;
  }

  body.light-mode .Navbar .navbar-middle h1 {
    color: #9ea9ec;
    margin-left: auto;
    margin-top: 2rem;
  }

  body.light-mode .Navbar .filters .fadeInUp {
    height: 0;
  }

  body.light-mode .Navbar .filters .fadeInUp .legend {
    height: 0;
  }

  body.light-mode .expand {
    background: #4051b5;
    flex-direction: column;
    padding-left: 0;
    padding-top: 1rem;
    top: 0;
    width: 100vw;
    z-index: 9998;
  }

  body.light-mode .expand > * {
    padding: 0;
    transition: all 0.15s ease-in-out;
  }

  body.light-mode .expand > * span.focused {
    background: #9ea9ec;
    border-radius: 10px;
    color: #fff;
    padding: 0;
    width: 20%;
  }

  body.light-mode .map-leaf {
    border-radius: 10px;
    height: 90vh;
  }

  body.light-mode .map-leaf h6 {
    color: #4051b5;
    display: inline;
    font-size: 16px !important;
    margin: 0;
    padding: 0;
  }

  body.light-mode .map-leaf .layer-control {
    background-color: #4051b5;
    font-family: 'Cereal', sans-serif !important;
  }

  body.light-mode .map-leaf .pup {
    width: 240px;
    z-index: 20;
  }

  body.light-mode .map-leaf .pup .c-btn-group {
    margin-left: 2rem;
  }

  body.light-mode .map-leaf .pup a {
    z-index: inherit;
  }

  body.light-mode .map-leaf .pup img {
    height: auto;
    margin-left: 50px;
    width: 100px;
  }

  body.light-mode .map-leaf .pup h1 {
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
  }

  body.light-mode .map-leaf .pup p {
    font-family: 'Cereal', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
    margin-top: 10px;
    text-align: center;
    width: 200px;
  }

  body.light-mode .home-banner {
    padding: 10px;
    position: relative;
  }

  body.light-mode .home-banner .home-img-1 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    width: 100%;
  }

  body.light-mode .home-banner .home-img-2 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.light-mode .home-banner .home-img-3 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.light-mode .home-banner .home-img-4 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.light-mode .home-banner .top-left-1 {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 5vw;
    font-weight: 900;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  body.light-mode .home-banner .top-left-2 {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 4vw;
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: relative;
    text-align: center;
  }

  body.light-mode .links {
    width: 100%;
  }

  body.light-mode .links a {
    background-image: linear-gradient(currentColor, #4051b5);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    color: #9ea9ec;
    text-decoration: none;
    transition: background-size 0.3s;
  }

  body.light-mode .links a:hover {
    background-size: 100% 2px;
  }

  body.light-mode .links a:visited {
    color: #c5cae9;
    text-decoration: none;
  }

  body.light-mode .links .link {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    font-size: 4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
  }

  body.light-mode .links .linkIcon {
    position: relative;
    right: 0;
    top: 5px;
  }

  body.light-mode .disc {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    font-size: 1rem;
    margin-top: 0;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
  }
}

@media (max-width: 650px) {
  body.light-mode .news-card {
    align-content: center;
    align-items: center;
    background-color: #c5cae9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
  }

  body.light-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 80%;
  }

  body.light-mode .news-card .news-card-heading {
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: 60%;
    order: 2;
  }

  body.light-mode .news-card .news-card-heading h3 {
    font-weight: 900;
    margin-bottom: 0;
  }

  body.light-mode .news-card .news-card-heading .news-desc {
    font-size: 0.8rem;
    margin-top: 0;
  }

  body.light-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  body.light-mode .news-card .news-card-heading .news-link a:hover,
  body.light-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #9ea9ec;
    border-radius: 5px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.light-mode .news-card .news-card-heading .news-link a {
    background-color: #4051b5;
    border-radius: 5px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 1050px) and (max-width: 1300px) {
  body.light-mode .news-card {
    align-content: center;
    align-items: center;
    background-color: #c5cae9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 2rem;
    padding: 1rem;
  }

  body.light-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 50%;
  }

  body.light-mode .news-card .news-card-heading {
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: 60%;
    order: 2;
  }

  body.light-mode .news-card .news-card-heading h3 {
    font-size: 60px;
    font-weight: 900;
  }

  body.light-mode .news-card .news-card-heading .news-desc {
    font-size: 1.5rem;
  }

  body.light-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 1rem;
  }

  body.light-mode .news-card .news-card-heading .news-link a:hover,
  body.light-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #9ea9ec;
    border-radius: 5px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.light-mode .news-card .news-card-heading .news-link a {
    background-color: #4051b5;
    border-radius: 5px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 651px) {
  body.light-mode .news-card {
    background-color: #c5cae9;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  body.light-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    max-width: 240px;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 100%;
  }

  body.light-mode .news-card .news-card-heading {
    border-radius: 10px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    margin-top: 1rem;
    order: 2;
    width: 58%;
  }

  body.light-mode .news-card .news-card-heading h3 {
    font-weight: 900;
  }

  body.light-mode .news-card .news-card-heading .news-desc {
    font-size: 0.8rem;
    margin-bottom: 1rem;

    /* IE */
    max-height: 150px;
    overflow: hidden;
    padding-top: 10px;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    /* CSS3 */
    white-space: -moz-pre-wrap;

    /* Firefox */
    white-space: -pre-wrap;

    /* Opera <7 */
    white-space: -o-pre-wrap;

    /* Opera 7 */
    word-wrap: break-word;
  }

  body.light-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 0.8rem;
    font-weight: 600;
    max-height: 20px;
    text-decoration: none;
  }

  body.light-mode .news-card .news-card-heading .news-link .news-icon {
    color: #4051b5;
    margin-left: 1rem;
    padding: 0.3rem;
    text-decoration: none;
  }

  body.light-mode .news-card .news-card-heading .news-link a:hover,
  body.light-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #9ea9ec;
    border-radius: 5px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.light-mode .news-card .news-card-heading .news-link a {
    background-color: #4051b5;
    border-radius: 5px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 769px) {
  body.light-mode .Home,
  body.light-mode .State {
    padding-top: 5rem;
  }

  body.light-mode .Navbar {
    background: #4051b5;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-width: 5rem;
    position: fixed;
    z-index: 99999;
  }

  body.light-mode .Navbar .navbar-left {
    margin-top: auto;
    order: 3;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 5rem;
  }

  body.light-mode .Navbar .navbar-left:hover {
    background: #4051b5;
  }

  body.light-mode .Navbar .navbar-middle {
    font-size: 0.6rem;
    order: 1;
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;
    width: 2.9rem;
    word-wrap: break-word;
  }

  body.light-mode .Navbar .navbar-right {
    display: flex;
    flex-direction: column;
    order: 2;
    padding-bottom: 0;
    padding-top: 0;
  }

  body.light-mode .Navbar .navbar-right:hover {
    background: #4051b5 !important;
  }

  body.light-mode .Navbar .navbar-right > span {
    display: flex;
    flex-direction: row;
    height: 1.2rem;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-top: 1.4rem;
  }

  body.light-mode .Navbar .navbar-right > span svg {
    stroke: #9ea9ec;
  }

  body.light-mode .Navbar .expand {
    background: #4051b5;
    flex-direction: column;
    padding-left: 0;
    padding-top: 5.5rem;
    top: 0;
    width: 15rem;
    z-index: -1 !important;
  }

  body.light-mode .Navbar .expand > * {
    border: 0;
    border-left: 1px solid #4051b5;
    color: #9ea9ec;
    font-size: 1.25rem;
    font-weight: 600;
    height: 2rem;
    width: 12rem;
  }

  body.light-mode .filters {
    align-content: right;
    background: rgba(158, 169, 236, 0.95);
    color: #4051b5 !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Cereal', sans-serif;
    height: 4rem;
    justify-content: center;
    padding-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    text-align: right;
    top: 0;
    z-index: 101;
  }

  body.light-mode .filters h1 {
    align-self: right;
    color: #4051b5;
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    position: absolute;
    right: 5%;
    top: 0.005rem;
  }

  body.light-mode .content-area {
    margin-bottom: 10rem;
    margin-left: 90px;
    margin-right: 1.75rem;
    margin-top: 1rem;
  }

  body.light-mode .map-leaf {
    border-radius: 0;
    height: 100vh;
    margin-bottom: 0;
    margin-left: -30px;
    margin-right: -45px;
    margin-top: -10px;
    z-index: 1;
  }

  body.light-mode .map-leaf h6 {
    color: #4051b5;
    display: inline;
    font-size: 16px !important;
    margin: 0;
    padding: 0;
  }

  body.light-mode .map-leaf .layer-control {
    background-color: #4051b5;
  }

  body.light-mode .map-leaf .pup {
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: auto;
    text-align: center;
    width: 220px;
    z-index: 20;
  }

  body.light-mode .map-leaf .pup a {
    display: inline-block;
    z-index: inherit;
  }

  body.light-mode .map-leaf .pup img {
    display: inline-block;
    height: auto;
    margin-left: 40px;
    width: 100px;
  }

  body.light-mode .map-leaf .pup h1 {
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
  }

  body.light-mode .map-leaf .pup p {
    font-family: 'Cereal', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
    margin-top: 10px;
    text-align: center;
    width: 180px;
  }

  body.light-mode .home-banner {
    margin-left: 160px;
    margin-right: 30px;
    margin-top: 40px;
    position: relative;
  }

  body.light-mode .home-banner .home-img-1 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    width: 100%;
  }

  body.light-mode .home-banner .home-img-2 {
    border-radius: 10px;
    height: auto;
    left: -10px;
    margin-bottom: 0;
    margin-top: 0.4rem;
    position: absolute;
    top: -30px;
    width: 28%;
  }

  body.light-mode .home-banner .home-img-3 {
    border-radius: 10px !important;
    font-size: 2vw !important;
    height: auto !important;
    left: -20px !important;
    margin-bottom: 0 !important;
    margin-top: 0.4rem !important;
    position: absolute !important;
    right: 400px;
    top: 34vw !important;
    width: 22% !important;
  }

  body.light-mode .home-banner .home-img-5 {
    border-radius: 10px;
    height: auto;
    left: -50px;
    margin-bottom: 0;
    margin-top: 0.4rem;
    position: absolute;
    top: 16.5vw;
    width: 25%;
  }

  body.light-mode .home-banner .top-left-1 {
    background: rgba(220, 222, 240, 0.77);
    border-radius: 10px;
    color: #4051b5;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 2vw;
    font-weight: 900;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 1vw;
    width: 30%;
  }

  body.light-mode .home-banner .top-left-2 {
    background: rgba(220, 222, 240, 0.77);
    border-radius: 10px;
    color: #4051b5;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 1.2vw;
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10.5vw;
    width: 30%;
  }

  body.light-mode .links {
    position: absolute;
    right: 10px;
    top: 20vw;
    width: auto;
  }

  body.light-mode .links .link {
    background: rgba(220, 222, 240, 0.77);
    border-radius: 10px;
    font-family: 'Cereal', sans-serif;
    font-size: 1.4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.3rem;
  }

  body.light-mode .links .link a {
    color: #4051b5;
    text-decoration: none;
  }

  body.light-mode .links .link:hover,
  body.light-mode .links .link:focus,
  body.light-mode .links .link:active {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    font-size: 1.4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.3rem;
    transform: translateX(-10px);
    transition: 0.3s;
  }

  body.light-mode .links .link:hover a,
  body.light-mode .links .link:focus a,
  body.light-mode .links .link:active a {
    color: #9ea9ec;
    text-decoration: none;
  }

  body.light-mode .links .linkIcon {
    position: relative;
    right: 0;
    top: 5px;
  }

  body.light-mode .disc {
    background-color: #4051b5;
    border-radius: 10px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    position: absolute;
    text-align: center;
    top: 52vw;
    width: 97%;
  }
}

body.light-mode .fadeInUp {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeinup;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body.light-mode .rotateDownRight {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: rotatedownright;
  animation-name: rotateDownRight;
}

@-webkit-keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

body.light-mode .rotateRightDown {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: rotaterightdown;
  animation-name: rotateRightDown;
}

@-webkit-keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

body.light-mode .fadeOutDown {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeoutdown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

body.light-mode .hostelGrid {
  border-radius: 15px;
  justify-content: center;
  text-align: center;
}

body.light-mode .hostel-card-title {
  background-color: #4c75f2;
  border-radius: 10px;
  color: #c5cae9;
  display: block;
  margin-left: 20%;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  width: 50%;
  z-index: 4;
}

body.light-mode .hostel-card-title h1 {
  color: #e8eaf6;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
}

body.light-mode .hostel-card-bg {
  background-color: #c5cae9;
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  gap: 0 20px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  height: 80%;
  margin: 3rem 1rem 2rem;
  text-align: center;
  width: 95%;
  z-index: 3;
}

body.light-mode .hostel-card-bg .card-subtitle {
  color: #4051b5;
  font-size: 1.2rem;
  font-weight: 900;
  margin-left: 40%;
  margin-top: 1.6rem;
  position: absolute;
  z-index: 3;
}

body.light-mode .hostel-card-bg p {
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  height: 50%;
  margin-left: -2rem;
  margin-top: 3.3rem;
  padding: 0.5rem;
  width: 80%;
  z-index: 3;
}

body.light-mode .hostel-card-bg .left1 {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 3rem;
  padding: 1rem;
}

body.light-mode .hostel-card-bg .left1 img {
  border-radius: 10px;
  display: flex;
  height: auto;
  max-width: 150px;
  width: 100%;
}

body.light-mode .hostel-card-bg .right {
  font-family: 'Cereal', sans-serif;
  grid-column-start: 2;
  grid-row-start: 1;
  text-align: center;
}

body.light-mode .hostel-card-bg .left2 {
  grid-column-end: span 2;
  grid-column-start: 1;
  grid-row-start: 2;
  margin-top: 5rem;
}

body.light-mode .hostel-card-bg .left2 .c-btn:hover,
body.light-mode .hostel-card-bg .left2 .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group {
  border-radius: 10px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  line-height: 1rem;
  margin: 0;
  margin-top: -4rem;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .learn-e span {
  display: inline;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .learn-e:hover {
  background-color: #20c997;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .fb:hover {
  background-color: #3391ff;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .fd:hover {
  background-color: #ff073a;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .girls {
  background-color: #fb5581;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .girls:hover {
  background-color: #ff073a99;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .hostel-card-bg .left2 .c-btn-group > .c-btn {
  border-radius: 0;
}

body.light-mode .left2 {
  font-family: 'Cereal', sans-serif;
  height: 100%;
  margin-top: 4rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

body.light-mode .left2 .c-btn-group {
  margin-left: -2rem;
  text-decoration: none;
}

body.light-mode .left2 .learn {
  background-color: #28a745;
  border-radius: 7px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  min-width: 60%;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  z-index: 6;
}

body.light-mode .left2 .map-link {
  background-color: #ff073a;
  border-radius: 7px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: auto;
  min-width: 60%;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  z-index: 6;
}

body.light-mode .exploreGrid {
  background-color: #c5cae9;
  border-radius: 15px;
  justify-content: center;
  margin: 10px;
  text-align: center;
}

body.light-mode .left-e .c-btn:hover,
body.light-mode .left-e .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.light-mode .left-e .c-btn-group {
  border-radius: 10px;
  bottom: 30px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  position: absolute;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 2000px) {
  body.light-mode .left-e .c-btn-group {
    left: 20px;
  }
}

@media (max-width: 1590px) {
  body.light-mode .left-e .c-btn-group {
    left: 35px;
  }
}

@media (max-width: 1410px) {
  body.light-mode .left-e .c-btn-group {
    left: 20px;
  }
}

@media (max-width: 1210px) {
  body.light-mode .left-e .c-btn-group {
    left: 10px;
  }
}

@media (max-width: 1110px) {
  body.light-mode .left-e .c-btn-group {
    left: 43px;
  }
}

@media (max-width: 1010px) {
  body.light-mode .left-e .c-btn-group {
    left: 25px;
  }
}

@media (max-width: 810px) {
  body.light-mode .left-e .c-btn-group {
    left: 10px;
  }
}

@media (max-width: 656px) {
  body.light-mode .left-e .c-btn-group {
    left: 63px;
  }
}

@media (max-width: 510px) {
  body.light-mode .left-e .c-btn-group {
    left: 33px;
  }
}

@media (max-width: 420px) {
  body.light-mode .left-e .c-btn-group {
    left: 22px;
  }
}

@media (max-width: 360px) {
  body.light-mode .left-e .c-btn-group {
    left: 10px;
  }
}

body.light-mode .left-e .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .learn-e:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .fb:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .insta {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .insta:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .linkedin {
  background: #f09433;
  background: #4875b4;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .linkedin:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .yt {
  background: #f00;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .yt:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .web:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .left-e .c-btn-group .git {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.light-mode .left-e .c-btn-group .git:hover {
  -webkit-filter: brightness(0.5) saturate(3);
  filter: brightness(0.5) saturate(3);
}

body.light-mode .left-e .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
}

body.light-mode .left-e .c-btn-group .fd:hover {
  -webkit-filter: saturate(3);
  filter: saturate(3);
}

body.light-mode .left-e .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.25rem;
}

body.light-mode .left-e .c-btn-group .hs:hover {
  -webkit-filter: saturate(3);
  filter: saturate(3);
}

body.light-mode .left-e .c-btn-group > .c-btn {
  border-radius: 0;
}

body.light-mode .left-e .card-img {
  border-radius: 10px;
  height: 160px;
  margin-left: 0.5rem;
  margin-top: 1rem;
  object-fit: cover;
  width: 80%;
}

body.light-mode .right-e {
  margin-right: 1rem;
}

body.light-mode .right-e h1 {
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  font-size: 1.5rem;
  margin-right: 1rem;
}

body.light-mode .right-e p {
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  font-weight: 400;
  margin-right: 2rem;
  padding-bottom: 1rem;
  width: 90%;
}

body.light-mode .links-e {
  line-height: 1.5;
  max-width: 80%;
  padding: 2rem;
  text-align: center;
}

body.light-mode .links-e .si {
  display: inline-block;
}

body.light-mode .box {
  box-sizing: border-box;
  display: grid;
  gap: 0 20px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}

body.light-mode .box .left-e {
  grid-column-start: 1;
  grid-row-start: 1;
}

body.light-mode .box .left-e img {
  height: 160px;
  max-width: 160px;
  width: 100%;
}

body.light-mode .box .right-e {
  grid-column-start: 2;
  grid-row-start: 1;
  margin-left: -1rem;
}

body.light-mode .box .links-e {
  grid-column-end: span 2;
  grid-column-start: 1;
  grid-row-start: 2;
}

body.light-mode .c-btn:hover,
body.light-mode .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.light-mode .c-btn-group {
  border-radius: 10px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  line-height: 1rem;
  margin: 0;
  margin-top: -4rem;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
}

body.light-mode .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
}

body.light-mode .c-btn-group .learn-e span {
  display: inline;
}

body.light-mode .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .girls {
  background-color: #fb5581;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .insta {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: #fff;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .linkedin {
  background: #f09433;
  background: #4875b4;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.light-mode .c-btn-group .linkedin:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .c-btn-group .yt {
  background: #f00;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
  padding: 0.3rem;
}

body.light-mode .c-btn-group .yt:hover {
  -webkit-filter: saturate(0.8);
  filter: saturate(0.8);
}

body.light-mode .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .sp {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .git {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  text-decoration: none;
}

body.light-mode .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .c-btn-group > .c-btn {
  border-radius: 0;
}

body.light-mode .campus-card {
  background-color: #c5cae9;
  border-radius: 10px;
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  text-align: center;
  width: 100%;
}

body.light-mode .campus-card .campus-card-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 0.5rem;
}

body.light-mode .campus-card small {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 90%;
}

body.light-mode .campus-card .campus-img {
  border-radius: 10px;
  height: 220px;
  margin: 1rem;
  object-fit: cover;
  width: 85%;
}

body.light-mode .campus-card .c-btn:hover,
body.light-mode .campus-card .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.light-mode .campus-card .c-btn-group {
  border-radius: 10px;
  bottom: 14px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  left: 17%;
  line-height: 1.2rem;
  position: fixed;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 1448px) {
  body.light-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1448px) {
  body.light-mode .campus-card .c-btn-group {
    left: 14%;
  }
}

@media (max-width: 1371px) {
  body.light-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1250px) {
  body.light-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1200px) {
  body.light-mode .campus-card .c-btn-group {
    left: 20%;
  }
}

@media (max-width: 980px) {
  body.light-mode .campus-card .c-btn-group {
    left: 16%;
  }
}

@media (max-width: 820px) {
  body.light-mode .campus-card .c-btn-group {
    left: 14%;
  }
}

@media (max-width: 778px) {
  body.light-mode .campus-card .c-btn-group {
    left: 24%;
  }
}

@media (max-width: 620px) {
  body.light-mode .campus-card .c-btn-group {
    left: 13%;
  }
}

@media (max-width: 528px) {
  body.light-mode .campus-card .c-btn-group {
    left: 12%;
  }
}

@media (max-width: 420px) {
  body.light-mode .campus-card .c-btn-group {
    left: 25vw;
  }
}

body.light-mode .campus-card .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
}

body.light-mode .campus-card .c-btn-group .learn-e span {
  display: inline;
}

body.light-mode .campus-card .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .campus-card .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .campus-card .c-btn-group .sp {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .campus-card .c-btn-group .git {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .campus-card .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .campus-card .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .campus-card .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .campus-card .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.light-mode .campus-card .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  text-decoration: none;
}

body.light-mode .campus-card .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.light-mode .campus-card .c-btn-group > .c-btn {
  border-radius: 0;
}

body.light-mode .linkGrid {
  background-color: #c5cae9;
  border-radius: 15px;
  justify-content: center;
  margin: 10px;
  margin-top: 0;
  text-align: center;
}

body.light-mode .linkGrid .link-card h1 {
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  font-size: 2rem;
  font-weight: 900;
}

body.light-mode .linkGrid .link-card a {
  background-image: linear-gradient(currentColor, #9ea9ec);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #4051b5;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  text-decoration: none;
  transition: background-size 0.3s;
}

body.light-mode .linkGrid .link-card a:hover,
body.light-mode .linkGrid .link-card a:focus {
  background-size: 100% 2px;
}

body.light-mode .linkGrid a:visited {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #5160bc;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  text-decoration: none;
  transition: background-size 0.3s;
}

body.light-mode .linkGrid a:visited a:hover,
body.light-mode .linkGrid a:visited a:focus {
  background-size: 100% 2px;
}

body.light-mode .hostel-link {
  font-size: 1rem !important;
  justify-content: center !important;
  margin-bottom: 0.2rem !important;
}

@media (max-width: 400px) {
  body.light-mode .hostel-card-bg {
    background-color: #c5cae9;
    border-radius: 10px;
    display: grid;
    gap: 0 20px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    height: 80%;
    margin: 3rem 1rem 2rem;
    text-align: center;
    width: 95%;
    z-index: 3;
  }

  body.light-mode .hostel-card-bg .card-subtitle {
    box-sizing: border-box;
    color: #4051b5;
    font-size: 1rem;
    font-weight: 900;
    margin-left: 40%;
    margin-top: 2rem;
    position: absolute;
    z-index: 3;
  }

  body.light-mode .hostel-card-bg p {
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    height: 50%;
    margin-top: 3rem;
    padding: 0.5rem;
    width: 80%;
    z-index: 3;
  }

  body.light-mode .hostel-card-bg .left1 {
    grid-column-start: 1;
    grid-row-start: 1;
    margin-top: 3rem;
    padding: 0.8rem;
  }

  body.light-mode .hostel-card-bg .left1 img {
    border-radius: 10px;
    display: flex;
    height: auto;
    max-width: 200px;
    width: 100%;
  }

  body.light-mode .hostel-card-bg .hostel-card-title {
    background-color: #4c75f2;
    border-radius: 10px;
    color: #c5cae9;
    margin-left: 20%;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    z-index: 4;
  }

  body.light-mode .hostel-card-bg .hostel-card-title h1 {
    color: #e8eaf6;
    font-family: 'Cereal', sans-serif;
    font-size: 1rem;
    font-weight: 900;
  }

  body.light-mode .hostel-card-bg .right {
    font-family: 'Cereal', sans-serif;
    font-size: 0.9rem;
    grid-column-start: 2;
    grid-row-start: 1;
    margin-right: 10px;
    text-align: center;
  }

  body.light-mode .hostel-card-bg .left2 {
    grid-column-end: span 2;
    grid-column-start: 1;
    grid-row-start: 2;
    margin-top: 5rem;
  }

  body.light-mode .hostel-card-bg .left2 .c-btn-group {
    margin-left: -2rem;
  }

  body.light-mode .hostel-link {
    font-size: 0.7rem !important;
    justify-content: center !important;
    margin-bottom: 0.2rem !important;
    margin-right: 0;
  }
}

body.light-mode .newsGrid {
  background-color: #c5cae9;
  border-radius: 10px;
}

@media (max-width: 769px) {
  body.light-mode .newsGrid {
    background-color: #c5cae9;
    border-radius: 10px;
    margin-left: 2vw;
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  body.light-mode .search {
    left: 150px;
    position: fixed;
    top: 25px;
    width: 1000px;
    z-index: 1000;
  }

  body.light-mode .search input[type='text'] {
    background-color: #e8eaf6;
    border: 1px solid #e8eaf6;
    border-radius: 10px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-size: 17px;
    font-weight: 600;
    left: 30px;
    padding-bottom: 7px;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 7px;
    position: absolute;
    width: 200px;
    z-index: 1000;
  }

  body.light-mode .search .search-icon {
    background-color: #4051b5;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #9ea9ec;
    display: inline-block;
    font-size: 17px;
    padding-bottom: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3px;
    position: absolute;
    z-index: 1001;
  }

  body.light-mode .search .filter-icon {
    background-color: #4051b5;
    border-radius: 15px;
    color: #9ea9ec;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 39px;
    left: 300px;
    line-height: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    z-index: 1001;
  }

  body.light-mode .search .filterCheckBoxes {
    background-color: #4051b5;
    border-radius: 15px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    left: 300px;
    padding: 1rem;
    position: absolute;
    top: 44px;
    z-index: 1001;
  }
}

@media (max-width: 769px) {
  body.light-mode .search {
    background: rgba(158, 169, 236, 0.9);
    border-radius: 20px;
    height: 55px;
    margin-left: 2vw;
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    width: 100%;
    z-index: 100;
  }

  body.light-mode .search input[type='text'] {
    background-color: #e8eaf6;
    border: 1px solid #e8eaf6;
    border-radius: 10px;
    color: #4051b5;
    font-family: 'Cereal', sans-serif;
    font-size: 17px;
    font-weight: 600;
    left: -10px;
    max-width: 200px;
    padding-bottom: 7px;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 7px;
    position: relative;
    top: 1px;
    width: 30vw;
    z-index: 1000;
  }

  body.light-mode .search .search-icon {
    background-color: #4051b5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 0;
    color: #9ea9ec;
    display: inline-block;
    font-size: 17px;
    left: 10px;
    padding-bottom: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1px;
    position: relative;
    top: 8px;
    z-index: 1001;
  }

  body.light-mode .search .filter-icon {
    background-color: #4051b5;
    border-radius: 15px;
    color: #9ea9ec;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 39px;
    line-height: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 1001;
  }

  body.light-mode .search .filterCheckBoxes {
    background-color: #4051b5;
    border-radius: 15px;
    color: #9ea9ec;
    font-family: 'Cereal', sans-serif;
    padding: 1rem;
    padding-top: 1rem;
    position: absolute;
    right: 0;
    top: 56px;
    z-index: 1001;
  }

  body.light-mode .search h1 {
    background-color: #9ea9ec;
    border-radius: 10px;
    color: #4051b5;
  }
}

body.light-mode .dummy {
  background-color: #e8eaf6;
  bottom: -200px;
  color: #e8eaf6;
  height: 10px;
  position: absolute;
  width: 100%;
}

body.light-mode .c {
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 95%;
  margin: 20px;
  margin-left: 2.5%;
  position: absolute;
  width: 100%;
  will-change: transform, opacity;
}

body.light-mode .front,
body.light-mode .back {
  background-size: cover;
}

@media (min-width: 672px) {
  body.light-mode .back {
    background-position: 50% 0%;
  }
}

@media (max-width: 672px) {
  body.light-mode .back {
    background-image: linear-gradient(180deg, rgba(116, 137, 219, 0) 0%, rgba(2, 17, 104, 0.77) 100%), url('./assets/events/rdv19.jpg');
    background-position: 50% 6%;
  }
}

body.light-mode .c.front {
  background: rgba(36, 58, 188, 0.8);
  white-space: pre-wrap;
  z-index: 100 !important;
}

body.light-mode .c.front img {
  height: 32px;
  margin-left: 20px;
  margin-right: 0;
  margin-top: 15px;
}

body.light-mode .c.front h1 {
  color: #fff;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 15px;
}

body.light-mode .c.front h1 .dates {
  background-color: #c5cae9;
  border-radius: 5px;
  color: #4051b5;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0 5px;
}

body.light-mode .c.front .description {
  display: relative;
  font-weight: 400;
  margin-top: 10px;
  white-space: pre-wrap;
  width: 88%;
}

body.light-mode .c.front .description .nestedh3 {
  background: none;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
}

@media (min-width: 1100px) {
  body.light-mode .c.front .description {
    font-size: 0.8em;
  }
}

body.light-mode .c.front .linksEvent {
  right: 20px;
  width: 6%;
}

@media (max-width: 769px) {
  body.light-mode .eventsGrid {
    margin-bottom: 50px;
    width: 98%;
  }

  body.light-mode .c {
    border-radius: 20px;
    cursor: pointer;
    height: 100%;
    margin-left: 15px;
    max-width: 100%;
    position: absolute;
    width: 100%;
    will-change: transform, opacity;
  }
}

@media (min-width: 769px) {
  body.light-mode .eventsGrid {
    margin-bottom: 50px;
    width: 98.5%;
  }
}

body.light-mode .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

body.light-mode .emptyImg {
  display: inline-block;
  height: auto;
  max-width: 550px;
  width: 80vw;
}

@media (max-width: 694px) {
  body.light-mode .c.front {
    background: rgba(36, 58, 188, 0.8);
    white-space: pre-wrap;
    z-index: 100 !important;
  }

  body.light-mode .c.front img {
    height: 26px;
    margin-left: 20px;
    margin-right: 0;
    margin-top: 7px;
  }

  body.light-mode .c.front h1 {
    color: #fff;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 7px;
  }

  body.light-mode .c.front h1 .dates {
    background-color: #c5cae9;
    border-radius: 5px;
    color: #4051b5;
    font-size: 0.8em;
    line-height: 1em;
    padding: 0 5px;
  }

  body.light-mode .c.front .description {
    display: relative;
    font-size: 13px;
    font-weight: 400;
    margin-left: -20px !important;
    margin-top: 5px;
    white-space: pre-wrap;
    width: 91%;
  }

  body.light-mode .c.front .description .nestedh3 {
    background: none;
    color: #fff;
    font-size: 13px;
    font-weight: 900;
  }
}

@media (max-width: 694px) and (min-width: 1100px) {
  body.light-mode .c.front .description {
    font-size: 0.8em;
  }
}

@media (max-width: 694px) {
  body.light-mode .c.back h1 {
    bottom: -15px;
    color: #fff;
    display: absolute;
    font-size: 30px;
    font-weight: 600 !important;
    left: 40px;
    position: fixed;
  }

  body.light-mode .c.back h1 img {
    bottom: 10px;
    height: 40px;
    left: 10px;
    position: fixed;
  }

  body.light-mode .c.back .dates {
    background-color: #c5cae9;
    border-radius: 10px;
    bottom: 12px;
    color: #4051b5;
    font-size: 10px;
    line-height: 20px;
    margin-left: 5px;
    padding: 0 5px;
    position: fixed;
  }
}

body.light-mode .linksEvent {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 8%;
}

body.light-mode .linksEvent .fbEvent {
  background-color: #1778f2;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.light-mode .linksEvent .fbEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .linksEvent .instaEvent {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.light-mode .linksEvent .instaEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.light-mode .linksEvent .siteEvent {
  background-color: #20c997;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.light-mode .linksEvent .siteEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

@media (min-width: 694px) {
  body.light-mode .c.back h1 {
    bottom: -20px;
    color: #fff;
    display: absolute;
    font-size: 40px;
    font-weight: 600 !important;
    left: 60px;
    position: fixed;
  }

  body.light-mode .c.back h1 img {
    bottom: 10px;
    height: 64px;
    left: 10px;
    position: fixed;
  }

  body.light-mode .c.back .dates {
    background-color: #c5cae9;
    border-radius: 10px;
    bottom: 15px;
    color: #4051b5;
    font-size: 16px;
    line-height: 26px;
    margin-left: 10px;
    padding: 0 5px;
    position: fixed;
  }
}

body.light-mode .rodal-h1 {
  color: #4051b5 !important;
}

body.light-mode .rodal-imp {
  backdrop-filter: blur(10px);
  background: rgba(67, 84, 182, 0.16);
}

body.light-mode .darkIcon {
  padding-left: 10px;
  padding-top: 20px;
}

body.light-mode .counterHR {
  border: 1px solid #9ea9ec;
}

body.dark-mode {
  background-color: #48484a;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body.dark-mode h1 {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 22px;
  font-weight: 900;
}

body.dark-mode h2 {
  font-family: 'Cereal', sans-serif;
  font-size: 18px !important;
  font-weight: 400;
}

body.dark-mode h3 {
  font-family: 'Cereal', sans-serif;
  font-size: 14px !important;
  font-weight: 400;
}

body.dark-mode h4 {
  font-family: 'Cereal', sans-serif;
  font-size: 13px !important;
  font-weight: 900;
}

body.dark-mode h5 {
  font-family: 'Cereal', sans-serif;
  font-size: 12px !important;
  font-weight: 900;
}

body.dark-mode h6 {
  font-family: 'Cereal', sans-serif;
  font-size: 11px !important;
  font-weight: 600;
}

body.dark-mode .App {
  min-height: 100vh;
}

body.dark-mode .switch-wrapper {
  position: relative;
}

body.dark-mode .switch-wrapper > div {
  position: absolute;
  transition: all 0.25s ease-in-out;
  width: 100%;
}

body.dark-mode .Navbar {
  display: flex;
  flex-direction: row;
  height: 10rem;
  justify-content: space-between;
  padding-bottom: 1rem;
}

body.dark-mode .Navbar > * {
  align-self: center;
}

body.dark-mode .Navbar a {
  color: inherit;
  text-decoration: none;
}

body.dark-mode .Navbar .navbar-left {
  color: #fff;
  cursor: pointer;
  font-family: 'Cereal', sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .Navbar .navbar-middle {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
}

body.dark-mode .Navbar .navbar-middle span {
  color: #fff;
  font-weight: 600;
}

body.dark-mode .Navbar .navbar-right {
  color: #000;
  cursor: pointer;
  font-family: 'Cereal', sans-serif;
  font-size: 40px;
  font-weight: 600;
  margin-right: -2px;
  transition: all 1s ease-in-out;
}

@media (min-width: 769px) {
  body.dark-mode .Navbar .navbar-right {
    width: 100%;
  }
}

@media (max-width: 769px) {
  body.dark-mode .Navbar .navbar-right {
    width: 2rem;
  }
}

body.dark-mode .Navbar .expand {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: 'Cereal', sans-serif;
  height: 100vh;
  position: absolute;
  transform: none;
  z-index: 9999;
}

body.dark-mode .Navbar .expand .love a {
  background-image: linear-gradient(#000, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #fff;
  font-weight: 900;
  transition: background-size 0.3s;
}

body.dark-mode .Navbar .expand .love a:hover {
  background-size: 100% 2px;
}

body.dark-mode .Navbar .expand .love:hover {
  background-color: #000;
  margin-left: 0;
  width: 60%;
}

body.dark-mode .Navbar .expand > * {
  padding: 1.5rem;
  padding-right: 1rem;
  transition: all 0.15s ease-in-out;
}

body.dark-mode .Navbar .expand > *:hover {
  background-color: #2c2c2e;
  border-radius: 20px;
  padding-right: 1rem;
  transition: all 0.2s ease-in-out;
}

body.dark-mode .Navbar .expand > * span.focused,
body.dark-mode .Navbar .expand > * span.hover {
  background: #1b1b1c;
  border-radius: 10px;
  color: #fff;
  padding: 0.75rem;
  transition: background-color 0.2s;
}

body.dark-mode .Navbar .expand .expand-bottom {
  align-self: flex-start;
  border: 0;
}

body.dark-mode .Navbar .expand .expand-bottom:hover {
  background: none;
}

body.dark-mode .Navbar .expand .expand-top {
  height: 0;
}

body.dark-mode .Navbar .expand .expand-top:hover {
  background: #000;
}

body.dark-mode .love:hover {
  background: #000;
  border-radius: 15px;
  margin-left: 1vw;
  width: 50%;
}

@media (max-width: 769px) {
  body.dark-mode .body {
    text-align: center;
  }

  body.dark-mode .content-area {
    display: inline-block;
    margin-top: 0.5rem;
    width: 95%;
  }

  body.dark-mode .Navbar {
    background-color: #000;
    height: 3rem;
  }

  body.dark-mode .Navbar .navbar-right {
    color: #fff;
    height: 30px;
    padding-right: 15px;
    z-index: 99999;
  }

  body.dark-mode .Navbar .navbar-middle {
    background-color: none;
    z-index: 999999;
  }

  body.dark-mode .Navbar .navbar-middle h1 {
    color: #fff;
    margin-left: auto;
    margin-top: 2rem;
  }

  body.dark-mode .Navbar .filters .fadeInUp {
    height: 0;
  }

  body.dark-mode .Navbar .filters .fadeInUp .legend {
    height: 0;
  }

  body.dark-mode .expand {
    background: #000;
    flex-direction: column;
    padding-left: 0;
    padding-top: 1rem;
    top: 0;
    width: 100vw;
    z-index: 9998;
  }

  body.dark-mode .expand > * {
    padding: 0;
    transition: all 0.15s ease-in-out;
  }

  body.dark-mode .expand > * span.focused {
    background: #1b1b1c;
    border-radius: 10px;
    color: #fff;
    padding: 0;
    width: 20%;
  }

  body.dark-mode .map-leaf {
    border-radius: 10px;
    height: 90vh;
  }

  body.dark-mode .map-leaf h6 {
    color: #000;
    display: inline;
    font-size: 16px !important;
    margin: 0;
    padding: 0;
  }

  body.dark-mode .map-leaf .layer-control {
    background-color: #000;
    font-family: 'Cereal', sans-serif !important;
  }

  body.dark-mode .map-leaf .pup {
    width: 240px;
    z-index: 20;
  }

  body.dark-mode .map-leaf .pup .c-btn-group {
    margin-left: 2rem;
  }

  body.dark-mode .map-leaf .pup a {
    z-index: inherit;
  }

  body.dark-mode .map-leaf .pup img {
    height: auto;
    margin-left: 50px;
    width: 100px;
  }

  body.dark-mode .map-leaf .pup h1 {
    color: #000;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
  }

  body.dark-mode .map-leaf .pup p {
    font-family: 'Cereal', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
    margin-top: 10px;
    text-align: center;
    width: 200px;
  }

  body.dark-mode .home-banner {
    padding: 10px;
    position: relative;
  }

  body.dark-mode .home-banner .home-img-1 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    width: 100%;
  }

  body.dark-mode .home-banner .home-img-2 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.dark-mode .home-banner .home-img-3 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.dark-mode .home-banner .home-img-4 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin-bottom: 0;
    margin-top: 0.4rem;
    width: 100%;
  }

  body.dark-mode .home-banner .top-left-1 {
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 5vw;
    font-weight: 900;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  body.dark-mode .home-banner .top-left-2 {
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 4vw;
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: relative;
    text-align: center;
  }

  body.dark-mode .links {
    width: 100%;
  }

  body.dark-mode .links a {
    background-image: linear-gradient(currentColor, #fff);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    color: #fff;
    text-decoration: none;
    transition: background-size 0.3s;
  }

  body.dark-mode .links a:hover {
    background-size: 100% 2px;
  }

  body.dark-mode .links a:visited {
    color: #fff;
    text-decoration: none;
  }

  body.dark-mode .links .link {
    background-color: #000;
    border-radius: 10px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    font-size: 4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
  }

  body.dark-mode .links .linkIcon {
    position: relative;
    right: 0;
    top: 5px;
  }

  body.dark-mode .disc {
    background-color: #000;
    border-radius: 10px;
    color: #f0f8ff;
    font-family: 'Cereal', sans-serif;
    font-size: 1rem;
    margin-top: 0;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
  }
}

@media (max-width: 650px) {
  body.dark-mode .news-card {
    align-content: center;
    align-items: center;
    background-color: #1b1b1c;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 1rem;
  }

  body.dark-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 80%;
  }

  body.dark-mode .news-card .news-card-heading {
    color: #fff;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: 60%;
    order: 2;
  }

  body.dark-mode .news-card .news-card-heading h3 {
    font-weight: 900;
    margin-bottom: 0;
  }

  body.dark-mode .news-card .news-card-heading .news-desc {
    font-size: 0.8rem;
    margin-top: 0;
  }

  body.dark-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 0.7rem;
    margin-bottom: 0;
  }

  body.dark-mode .news-card .news-card-heading .news-link a:hover,
  body.dark-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #1b1b1c;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.dark-mode .news-card .news-card-heading .news-link a {
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 1050px) and (max-width: 1300px) {
  body.dark-mode .news-card {
    align-content: center;
    align-items: center;
    background-color: #1b1b1c;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 2rem;
    padding: 1rem;
  }

  body.dark-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 1rem;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 50%;
  }

  body.dark-mode .news-card .news-card-heading {
    color: #fff;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: 60%;
    order: 2;
  }

  body.dark-mode .news-card .news-card-heading h3 {
    font-size: 60px;
    font-weight: 900;
  }

  body.dark-mode .news-card .news-card-heading .news-desc {
    font-size: 1.5rem;
  }

  body.dark-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 1rem;
  }

  body.dark-mode .news-card .news-card-heading .news-link a:hover,
  body.dark-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #1b1b1c;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.dark-mode .news-card .news-card-heading .news-link a {
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 651px) {
  body.dark-mode .news-card {
    background-color: #1b1b1c;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  body.dark-mode .news-card .news-img {
    border-radius: 10px;
    left: -1rem;
    margin-left: 1rem;
    margin-top: 2rem;
    max-width: 240px;
    object-fit: cover;
    order: 1;
    position: relative;
    width: 100%;
  }

  body.dark-mode .news-card .news-card-heading {
    border-radius: 10px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    margin-top: 1rem;
    order: 2;
    width: 58%;
  }

  body.dark-mode .news-card .news-card-heading h3 {
    font-weight: 900;
  }

  body.dark-mode .news-card .news-card-heading .news-desc {
    font-size: 0.8rem;
    margin-bottom: 1rem;

    /* IE */
    max-height: 150px;
    overflow: hidden;
    padding-top: 10px;
    text-overflow: ellipsis;
    white-space: pre-wrap;

    /* CSS3 */
    white-space: -moz-pre-wrap;

    /* Firefox */
    white-space: -pre-wrap;

    /* Opera <7 */
    white-space: -o-pre-wrap;

    /* Opera 7 */
    word-wrap: break-word;
  }

  body.dark-mode .news-card .news-card-heading .news-link {
    color: #4074c6;
    font-size: 0.8rem;
    font-weight: 600;
    max-height: 20px;
    text-decoration: none;
  }

  body.dark-mode .news-card .news-card-heading .news-link .news-icon {
    color: #fff;
    margin-left: 1rem;
    padding: 0.3rem;
    text-decoration: none;
  }

  body.dark-mode .news-card .news-card-heading .news-link a:hover,
  body.dark-mode .news-card .news-card-heading .news-link a:focus {
    background-color: #1b1b1c;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
  }

  body.dark-mode .news-card .news-card-heading .news-link a {
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    line-height: 20px;
    margin-left: 10px;
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.6rem;
    padding-top: 0.3rem;
    position: relative;
    text-decoration: none;
    transition: 0.3s;
  }
}

@media (min-width: 769px) {
  body.dark-mode .Home,
  body.dark-mode .State {
    padding-top: 5rem;
  }

  body.dark-mode .Navbar {
    background: #000;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    min-width: 5rem;
    position: fixed;
    z-index: 99999;
  }

  body.dark-mode .Navbar .navbar-left {
    margin-top: auto;
    order: 3;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    transition: all 0.2s ease-in-out;
    width: 5rem;
  }

  body.dark-mode .Navbar .navbar-left:hover {
    background: #000;
  }

  body.dark-mode .Navbar .navbar-middle {
    font-size: 0.6rem;
    order: 1;
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: center;
    width: 2.9rem;
    word-wrap: break-word;
  }

  body.dark-mode .Navbar .navbar-right {
    display: flex;
    flex-direction: column;
    order: 2;
    padding-bottom: 0;
    padding-top: 0;
  }

  body.dark-mode .Navbar .navbar-right:hover {
    background: #000 !important;
  }

  body.dark-mode .Navbar .navbar-right > span {
    display: flex;
    flex-direction: row;
    height: 1.2rem;
    justify-content: center;
    margin-bottom: 1.5rem;
    margin-top: 1.4rem;
  }

  body.dark-mode .Navbar .navbar-right > span svg {
    stroke: #48484a;
  }

  body.dark-mode .Navbar .expand {
    background: #000;
    flex-direction: column;
    padding-left: 0;
    padding-top: 5.5rem;
    top: 0;
    width: 15rem;
    z-index: -1 !important;
  }

  body.dark-mode .Navbar .expand > * {
    border: 0;
    border-left: 1px solid #000;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    height: 2rem;
    width: 12rem;
  }

  body.dark-mode .filters {
    align-content: right;
    background: rgba(44, 44, 46, 0.95);
    color: #fff !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: 'Cereal', sans-serif;
    height: 4rem;
    justify-content: center;
    padding-bottom: 1rem;
    position: -webkit-sticky;
    position: sticky;
    text-align: right;
    top: 0;
    z-index: 101;
  }

  body.dark-mode .filters h1 {
    align-self: right;
    color: #fff;
    font-size: 2rem;
    font-weight: 400;
    padding-bottom: 0.5rem;
    position: absolute;
    right: 5%;
    top: 0.005rem;
  }

  body.dark-mode .content-area {
    margin-bottom: 10rem;
    margin-left: 90px;
    margin-right: 1.75rem;
    margin-top: 1rem;
  }

  body.dark-mode .map-leaf {
    border-radius: 0;
    height: 100vh;
    margin-bottom: 0;
    margin-left: -30px;
    margin-right: -45px;
    margin-top: -10px;
    z-index: 1;
  }

  body.dark-mode .map-leaf h6 {
    color: #000;
    display: inline;
    font-size: 16px !important;
    margin: 0;
    padding: 0;
  }

  body.dark-mode .map-leaf .layer-control {
    background-color: #000;
  }

  body.dark-mode .map-leaf .pup {
    font-family: 'Cereal', sans-serif;
    font-weight: 600;
    height: auto;
    text-align: center;
    width: 220px;
    z-index: 20;
  }

  body.dark-mode .map-leaf .pup a {
    display: inline-block;
    z-index: inherit;
  }

  body.dark-mode .map-leaf .pup img {
    display: inline-block;
    height: auto;
    margin-left: 40px;
    width: 100px;
  }

  body.dark-mode .map-leaf .pup h1 {
    color: #000;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
  }

  body.dark-mode .map-leaf .pup p {
    font-family: 'Cereal', sans-serif;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
    margin-top: 10px;
    text-align: center;
    width: 180px;
  }

  body.dark-mode .home-banner {
    margin-left: 160px;
    margin-right: 30px;
    margin-top: 40px;
    position: relative;
  }

  body.dark-mode .home-banner .home-img-1 {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    width: 100%;
  }

  body.dark-mode .home-banner .home-img-2 {
    border-radius: 10px;
    height: auto;
    left: -10px;
    margin-bottom: 0;
    margin-top: 0.4rem;
    position: absolute;
    top: -30px;
    width: 28%;
  }

  body.dark-mode .home-banner .home-img-3 {
    border-radius: 10px !important;
    font-size: 2vw !important;
    height: auto !important;
    left: -20px !important;
    margin-bottom: 0 !important;
    margin-top: 0.4rem !important;
    position: absolute !important;
    right: 400px;
    top: 34vw !important;
    width: 22% !important;
  }

  body.dark-mode .home-banner .home-img-5 {
    border-radius: 10px;
    height: auto;
    left: -50px;
    margin-bottom: 0;
    margin-top: 0.4rem;
    position: absolute;
    top: 16.5vw;
    width: 25%;
  }

  body.dark-mode .home-banner .top-left-1 {
    background: rgba(40, 40, 44, 0.77);
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 2vw;
    font-weight: 900;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 1vw;
    width: 30%;
  }

  body.dark-mode .home-banner .top-left-2 {
    background: rgba(40, 40, 44, 0.77);
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-family: 'Cereal', sans-serif;
    font-size: 1.2vw;
    font-weight: 600;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    position: absolute;
    right: 10px;
    text-align: center;
    top: 10.5vw;
    width: 30%;
  }

  body.dark-mode .links {
    position: absolute;
    right: 10px;
    top: 20vw;
    width: auto;
  }

  body.dark-mode .links .link {
    background: rgba(40, 40, 44, 0.77);
    border-radius: 10px;
    font-family: 'Cereal', sans-serif;
    font-size: 1.4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  body.dark-mode .links .link a {
    color: #fff;
    text-decoration: none;
  }

  body.dark-mode .links .link:hover,
  body.dark-mode .links .link:focus,
  body.dark-mode .links .link:active {
    background-color: #fff;
    border-radius: 10px;
    color: #1b1b1c;
    font-family: 'Cereal', sans-serif;
    font-size: 1.4vw;
    margin-top: 0.4rem;
    padding-bottom: 0.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.3rem;
    transform: translateX(-10px);
    transition: 0.3s;
  }

  body.dark-mode .links .link:hover a,
  body.dark-mode .links .link:focus a,
  body.dark-mode .links .link:active a {
    color: #1b1b1c;
    text-decoration: none;
  }

  body.dark-mode .links .linkIcon {
    position: relative;
    right: 0;
    top: 5px;
  }

  body.dark-mode .disc {
    background-color: #000;
    border-radius: 10px;
    color: #f0f8ff;
    font-family: 'Cereal', sans-serif;
    font-size: 20px;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    position: absolute;
    text-align: center;
    top: 52vw;
    width: 97%;
  }
}

body.dark-mode .fadeInUp {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeinup;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body.dark-mode .rotateDownRight {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: rotatedownright;
  animation-name: rotateDownRight;
}

@-webkit-keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotateDownRight {
  100% {
    transform: rotate(-90deg);
  }
}

body.dark-mode .rotateRightDown {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: rotaterightdown;
  animation-name: rotateRightDown;
}

@-webkit-keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

@keyframes rotateRightDown {
  0% {
    transform: rotate(-90deg);
  }
}

body.dark-mode .fadeOutDown {
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeoutdown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

body.dark-mode .hostelGrid {
  border-radius: 15px;
  justify-content: center;
  text-align: center;
}

body.dark-mode .hostel-card-title {
  background-color: #2b2b2e;
  border-radius: 10px;
  color: #fff;
  display: block;
  margin-left: 20%;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  width: 50%;
  z-index: 4;
}

body.dark-mode .hostel-card-title h1 {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
}

body.dark-mode .hostel-card-bg {
  background-color: #1b1b1c;
  border-radius: 10px;
  box-sizing: border-box;
  display: grid;
  gap: 0 20px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  height: 80%;
  margin: 3rem 1rem 2rem;
  text-align: center;
  width: 95%;
  z-index: 3;
}

body.dark-mode .hostel-card-bg .card-subtitle {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 900;
  margin-left: 40%;
  margin-top: 1.6rem;
  position: absolute;
  z-index: 3;
}

body.dark-mode .hostel-card-bg p {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  height: 50%;
  margin-left: -2rem;
  margin-top: 3.3rem;
  padding: 0.5rem;
  width: 80%;
  z-index: 3;
}

body.dark-mode .hostel-card-bg .left1 {
  grid-column-start: 1;
  grid-row-start: 1;
  margin-top: 3rem;
  padding: 1rem;
}

body.dark-mode .hostel-card-bg .left1 img {
  border-radius: 10px;
  display: flex;
  height: auto;
  max-width: 150px;
  width: 100%;
}

body.dark-mode .hostel-card-bg .right {
  font-family: 'Cereal', sans-serif;
  grid-column-start: 2;
  grid-row-start: 1;
  text-align: center;
}

body.dark-mode .hostel-card-bg .left2 {
  grid-column-end: span 2;
  grid-column-start: 1;
  grid-row-start: 2;
  margin-top: 5rem;
}

body.dark-mode .hostel-card-bg .left2 .c-btn:hover,
body.dark-mode .hostel-card-bg .left2 .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group {
  border-radius: 10px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  line-height: 1rem;
  margin: 0;
  margin-top: -4rem;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .learn-e span {
  display: inline;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .learn-e:hover {
  background-color: #20c997;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .fb:hover {
  background-color: #3391ff;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .fd:hover {
  background-color: #ff073a;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .girls {
  background-color: #fb5581;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .girls:hover {
  background-color: #ff073a99;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  padding-right: 0.5rem;
  text-decoration: none;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .hostel-card-bg .left2 .c-btn-group > .c-btn {
  border-radius: 0;
}

body.dark-mode .left2 {
  font-family: 'Cereal', sans-serif;
  height: 100%;
  margin-top: 4rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

body.dark-mode .left2 .c-btn-group {
  margin-left: -2rem;
  text-decoration: none;
}

body.dark-mode .left2 .learn {
  background-color: #28a745;
  border-radius: 7px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  min-width: 60%;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  z-index: 6;
}

body.dark-mode .left2 .map-link {
  background-color: #ff073a;
  border-radius: 7px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: auto;
  min-width: 60%;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  z-index: 6;
}

body.dark-mode .exploreGrid {
  background-color: #1b1b1c;
  border-radius: 15px;
  justify-content: center;
  margin: 10px;
  text-align: center;
}

body.dark-mode .left-e .c-btn:hover,
body.dark-mode .left-e .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.dark-mode .left-e .c-btn-group {
  border-radius: 10px;
  bottom: 30px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0;
  position: absolute;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 2000px) {
  body.dark-mode .left-e .c-btn-group {
    left: 20px;
  }
}

@media (max-width: 1590px) {
  body.dark-mode .left-e .c-btn-group {
    left: 35px;
  }
}

@media (max-width: 1410px) {
  body.dark-mode .left-e .c-btn-group {
    left: 20px;
  }
}

@media (max-width: 1210px) {
  body.dark-mode .left-e .c-btn-group {
    left: 10px;
  }
}

@media (max-width: 1110px) {
  body.dark-mode .left-e .c-btn-group {
    left: 43px;
  }
}

@media (max-width: 1010px) {
  body.dark-mode .left-e .c-btn-group {
    left: 25px;
  }
}

@media (max-width: 810px) {
  body.dark-mode .left-e .c-btn-group {
    left: 10px;
  }
}

@media (max-width: 656px) {
  body.dark-mode .left-e .c-btn-group {
    left: 63px;
  }
}

@media (max-width: 510px) {
  body.dark-mode .left-e .c-btn-group {
    left: 33px;
  }
}

@media (max-width: 420px) {
  body.dark-mode .left-e .c-btn-group {
    left: 22px;
  }
}

@media (max-width: 360px) {
  body.dark-mode .left-e .c-btn-group {
    left: 10px;
  }
}

body.dark-mode .left-e .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .learn-e:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .fb:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .insta {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .insta:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .linkedin {
  background: #f09433;
  background: #4875b4;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .linkedin:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .yt {
  background: #f00;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .yt:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .web:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .left-e .c-btn-group .git {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .left-e .c-btn-group .git:hover {
  -webkit-filter: brightness(0.5) saturate(3);
  filter: brightness(0.5) saturate(3);
}

body.dark-mode .left-e .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
}

body.dark-mode .left-e .c-btn-group .fd:hover {
  -webkit-filter: saturate(3);
  filter: saturate(3);
}

body.dark-mode .left-e .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.25rem;
}

body.dark-mode .left-e .c-btn-group .hs:hover {
  -webkit-filter: saturate(3);
  filter: saturate(3);
}

body.dark-mode .left-e .c-btn-group > .c-btn {
  border-radius: 0;
}

body.dark-mode .left-e .card-img {
  border-radius: 10px;
  height: 160px;
  margin-left: 0.5rem;
  margin-top: 1rem;
  object-fit: cover;
  width: 80%;
}

body.dark-mode .right-e {
  margin-right: 1rem;
}

body.dark-mode .right-e h1 {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 1.5rem;
  margin-right: 1rem;
}

body.dark-mode .right-e p {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-weight: 400;
  margin-right: 2rem;
  padding-bottom: 1rem;
  width: 90%;
}

body.dark-mode .links-e {
  line-height: 1.5;
  max-width: 80%;
  padding: 2rem;
  text-align: center;
}

body.dark-mode .links-e .si {
  display: inline-block;
}

body.dark-mode .box {
  box-sizing: border-box;
  display: grid;
  gap: 0 20px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}

body.dark-mode .box .left-e {
  grid-column-start: 1;
  grid-row-start: 1;
}

body.dark-mode .box .left-e img {
  height: 160px;
  max-width: 160px;
  width: 100%;
}

body.dark-mode .box .right-e {
  grid-column-start: 2;
  grid-row-start: 1;
  margin-left: -1rem;
}

body.dark-mode .box .links-e {
  grid-column-end: span 2;
  grid-column-start: 1;
  grid-row-start: 2;
}

body.dark-mode .c-btn:hover,
body.dark-mode .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.dark-mode .c-btn-group {
  border-radius: 10px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  line-height: 1rem;
  margin: 0;
  margin-top: -4rem;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
}

body.dark-mode .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
}

body.dark-mode .c-btn-group .learn-e span {
  display: inline;
}

body.dark-mode .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .girls {
  background-color: #fb5581;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .insta {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: #fff;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .linkedin {
  background: #f09433;
  background: #4875b4;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
}

body.dark-mode .c-btn-group .linkedin:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .c-btn-group .yt {
  background: #f00;
  color: #fff;
  display: flex;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  flex-direction: row;
  justify-content: center;
  padding: 0.3rem;
}

body.dark-mode .c-btn-group .yt:hover {
  -webkit-filter: saturate(0.8);
  filter: saturate(0.8);
}

body.dark-mode .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .sp {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .git {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  text-decoration: none;
}

body.dark-mode .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .c-btn-group > .c-btn {
  border-radius: 0;
}

body.dark-mode .campus-card {
  background-color: #1b1b1c;
  border-radius: 10px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  text-align: center;
  width: 100%;
}

body.dark-mode .campus-card .campus-card-heading {
  font-size: 1.5rem;
  font-weight: 900;
  padding-bottom: 0.5rem;
}

body.dark-mode .campus-card small {
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 90%;
}

body.dark-mode .campus-card .campus-img {
  border-radius: 10px;
  height: 220px;
  margin: 1rem;
  object-fit: cover;
  width: 85%;
}

body.dark-mode .campus-card .c-btn:hover,
body.dark-mode .campus-card .c-btn:focus {
  background-color: #f00;
  color: #fff;
  cursor: pointer;
}

body.dark-mode .campus-card .c-btn-group {
  border-radius: 10px;
  bottom: 14px;
  display: inline-flex;
  font-family: 'Cereal', sans-serif;
  left: 17%;
  line-height: 1.2rem;
  position: fixed;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 1448px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1448px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 14%;
  }
}

@media (max-width: 1371px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1250px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 17%;
  }
}

@media (max-width: 1200px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 20%;
  }
}

@media (max-width: 980px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 16%;
  }
}

@media (max-width: 820px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 14%;
  }
}

@media (max-width: 778px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 24%;
  }
}

@media (max-width: 620px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 13%;
  }
}

@media (max-width: 528px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 12%;
  }
}

@media (max-width: 420px) {
  body.dark-mode .campus-card .c-btn-group {
    left: 25vw;
  }
}

body.dark-mode .campus-card .c-btn-group .learn-e {
  background-color: #28a745;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-family: 'Cereal', sans-serif;
  font-size: 1rem;
  justify-content: center;
  padding: 0.25rem;
}

body.dark-mode .campus-card .c-btn-group .learn-e span {
  display: inline;
}

body.dark-mode .campus-card .c-btn-group .fb {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .campus-card .c-btn-group .web {
  background-color: #20c997;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .campus-card .c-btn-group .sp {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .campus-card .c-btn-group .git {
  background-color: #000;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .campus-card .c-btn-group .acad {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .campus-card .c-btn-group .eat {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .campus-card .c-btn-group .fd {
  background-color: #dc3545;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .campus-card .c-btn-group .hs {
  background-color: #007bff;
  color: #fff;
  padding: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body.dark-mode .campus-card .c-btn-group .map {
  background-color: #4c75f2;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
  text-decoration: none;
}

body.dark-mode .campus-card .c-btn-group .ph {
  background-color: #fd7e14;
  color: #fff;
  padding: 0.25rem;
  padding-bottom: 0;
}

body.dark-mode .campus-card .c-btn-group > .c-btn {
  border-radius: 0;
}

body.dark-mode .linkGrid {
  background-color: #1b1b1c;
  border-radius: 15px;
  justify-content: center;
  margin: 10px;
  margin-top: 0;
  text-align: center;
}

body.dark-mode .linkGrid .link-card h1 {
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 2rem;
  font-weight: 900;
}

body.dark-mode .linkGrid .link-card a {
  background-image: linear-gradient(currentColor, #1b1b1c);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #fff;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  text-decoration: none;
  transition: background-size 0.3s;
}

body.dark-mode .linkGrid .link-card a:hover,
body.dark-mode .linkGrid .link-card a:focus {
  background-size: 100% 2px;
}

body.dark-mode .linkGrid a:visited {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  color: #6c757d;
  font-family: 'Cereal', sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  position: relative;
  text-decoration: none;
  text-decoration: none;
  transition: background-size 0.3s;
}

body.dark-mode .linkGrid a:visited a:hover,
body.dark-mode .linkGrid a:visited a:focus {
  background-size: 100% 2px;
}

body.dark-mode .hostel-link {
  font-size: 1rem !important;
  justify-content: center !important;
  margin-bottom: 0.2rem !important;
}

@media (max-width: 400px) {
  body.dark-mode .hostel-card-bg {
    background-color: #1b1b1c;
    border-radius: 10px;
    box-sizing: border-box;
    display: grid;
    gap: 0 20px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    height: 80%;
    margin: 3rem 1rem 2rem;
    text-align: center;
    width: 95%;
    z-index: 3;
  }

  body.dark-mode .hostel-card-bg .card-subtitle {
    color: #000;
    font-size: 1rem;
    font-weight: 900;
    margin-left: 40%;
    margin-top: 2rem;
    position: absolute;
    z-index: 3;
  }

  body.dark-mode .hostel-card-bg p {
    color: #000;
    font-family: 'Cereal', sans-serif;
    height: 50%;
    margin-top: 3rem;
    padding: 0.5rem;
    width: 80%;
    z-index: 3;
  }

  body.dark-mode .hostel-card-bg .left1 {
    grid-column-start: 1;
    grid-row-start: 1;
    margin-top: 3rem;
    padding: 0.8rem;
  }

  body.dark-mode .hostel-card-bg .left1 img {
    border-radius: 10px;
    display: flex;
    height: auto;
    max-width: 200px;
    width: 100%;
  }

  body.dark-mode .hostel-card-bg .hostel-card-title {
    background-color: #2b2b2e;
    border-radius: 10px;
    color: #fff;
    margin-left: 20%;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    position: absolute;
    z-index: 4;
  }

  body.dark-mode .hostel-card-bg .hostel-card-title h1 {
    color: #fff;
    font-family: 'Cereal', sans-serif;
    font-size: 1rem;
    font-weight: 900;
  }

  body.dark-mode .hostel-card-bg .right {
    font-family: 'Cereal', sans-serif;
    font-size: 0.9rem;
    grid-column-start: 2;
    grid-row-start: 1;
    margin-right: 10px;
    text-align: center;
  }

  body.dark-mode .hostel-card-bg .left2 {
    grid-column-end: span 2;
    grid-column-start: 1;
    grid-row-start: 2;
    margin-top: 5rem;
  }

  body.dark-mode .hostel-card-bg .left2 .c-btn-group {
    margin-left: -2rem;
  }

  body.dark-mode .hostel-link {
    font-size: 0.7rem !important;
    justify-content: center !important;
    margin-bottom: 0.2rem !important;
    margin-right: 0;
  }
}

body.dark-mode .newsGrid {
  background-color: #1b1b1c;
  border-radius: 10px;
}

@media (max-width: 769px) {
  body.dark-mode .newsGrid {
    background-color: #1b1b1c;
    border-radius: 10px;
    margin-left: 2vw;
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  body.dark-mode .search {
    left: 150px;
    position: fixed;
    top: 25px;
    width: 1000px;
    z-index: 1000;
  }

  body.dark-mode .search input[type='text'] {
    background-color: #d7d5d5;
    border: 1px solid #1e1e20;
    border-radius: 10px;
    color: #000;
    font-family: 'Cereal', sans-serif;
    font-size: 17px;
    font-weight: 600;
    left: 30px;
    padding-bottom: 7px;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 7px;
    position: absolute;
    width: 200px;
    z-index: 1000;
  }

  body.dark-mode .search .search-icon {
    background-color: #000;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #fff;
    display: inline-block;
    font-size: 17px;
    padding-bottom: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3px;
    position: absolute;
    z-index: 1001;
  }

  body.dark-mode .search .filter-icon {
    background-color: #000;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 39px;
    left: 300px;
    line-height: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    z-index: 1001;
  }

  body.dark-mode .search .filterCheckBoxes {
    background-color: #000;
    border-radius: 15px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    left: 300px;
    padding: 1rem;
    position: absolute;
    top: 44px;
    z-index: 1001;
  }
}

@media (max-width: 769px) {
  body.dark-mode .search {
    background: rgba(44, 44, 46, 0.9);
    border-radius: 20px;
    height: 55px;
    margin-left: 2vw;
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    width: 100%;
    z-index: 100;
  }

  body.dark-mode .search input[type='text'] {
    background-color: #fff;
    border: 1px solid #1e1e20;
    border-radius: 10px;
    color: #000;
    font-family: 'Cereal', sans-serif;
    font-size: 17px;
    font-weight: 600;
    left: -10px;
    max-width: 200px;
    padding-bottom: 7px;
    padding-left: 2rem;
    padding-right: 1rem;
    padding-top: 7px;
    position: relative;
    top: 1px;
    width: 30vw;
    z-index: 1000;
  }

  body.dark-mode .search .search-icon {
    background-color: #000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 0;
    color: #fff;
    display: inline-block;
    font-size: 17px;
    left: 10px;
    padding-bottom: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1px;
    position: relative;
    top: 8px;
    z-index: 1001;
  }

  body.dark-mode .search .filter-icon {
    background-color: #000;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    height: 39px;
    line-height: 36px;
    padding-left: 1rem;
    padding-right: 1rem;
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 1001;
  }

  body.dark-mode .search .filterCheckBoxes {
    background-color: #000;
    border-radius: 15px;
    color: #fff;
    font-family: 'Cereal', sans-serif;
    padding: 1rem;
    padding-top: 1rem;
    position: absolute;
    right: 0;
    top: 56px;
    z-index: 1001;
  }

  body.dark-mode .search h1 {
    background-color: #1b1b1c;
    border-radius: 10px;
    color: #fff;
  }
}

body.dark-mode .dummy {
  background-color: #48484a;
  bottom: -200px;
  color: #48484a;
  height: 10px;
  position: absolute;
  width: 100%;
}

body.dark-mode .c {
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  height: 95%;
  margin: 20px;
  margin-left: 2.5%;
  position: absolute;
  width: 100%;
  will-change: transform, opacity;
}

body.dark-mode .front,
body.dark-mode .back {
  background-size: cover;
}

@media (min-width: 672px) {
  body.dark-mode .back {
    background-image: linear-gradient(180deg, rgba(2, 17, 104, 0) 0%, rgba(0, 0, 0, 0.96) 100%), url('./assets/events/rdv19.jpg');
    background-position: 50% 0%;
  }
}

@media (max-width: 672px) {
  body.dark-mode .back {
    background-image: linear-gradient(180deg, rgba(2, 17, 104, 0) 0%, rgba(0, 0, 0, 0.96) 100%), url('./assets/events/rdv19.jpg');
    background-position: 50% 6%;
  }
}

body.dark-mode .c.front {
  background: #1b1b1d;
  white-space: pre-wrap;
  z-index: 100 !important;
}

body.dark-mode .c.front img {
  height: 32px;
  margin-left: 20px;
  margin-right: 0;
  margin-top: 15px;
}

body.dark-mode .c.front h1 {
  color: #fff;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 15px;
}

body.dark-mode .c.front h1 .dates {
  background-color: #1b1b1c;
  border-radius: 5px;
  color: #fff;
  font-size: 0.8em;
  line-height: 1em;
  padding: 0 5px;
}

body.dark-mode .c.front .description {
  display: relative;
  font-weight: 400;
  margin-top: 10px;
  white-space: pre-wrap;
  width: 88%;
}

body.dark-mode .c.front .description .nestedh3 {
  background: none;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
}

@media (min-width: 1100px) {
  body.dark-mode .c.front .description {
    font-size: 0.8em;
  }
}

body.dark-mode .c.front .linksEvent {
  right: 20px;
  width: 6%;
}

@media (max-width: 769px) {
  body.dark-mode .eventsGrid {
    margin-bottom: 50px;
    width: 98%;
  }

  body.dark-mode .c {
    border-radius: 20px;
    cursor: pointer;
    height: 100%;
    margin-left: 15px;
    max-width: 100%;
    position: absolute;
    width: 100%;
    will-change: transform, opacity;
  }
}

@media (min-width: 769px) {
  body.dark-mode .eventsGrid {
    margin-bottom: 50px;
    width: 98.5%;
  }
}

body.dark-mode .empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

body.dark-mode .emptyImg {
  display: inline-block;
  height: auto;
  max-width: 550px;
  width: 80vw;
}

@media (max-width: 694px) {
  body.dark-mode .c.front {
    background: #1b1b1d;
    white-space: pre-wrap;
    z-index: 100 !important;
  }

  body.dark-mode .c.front img {
    height: 26px;
    margin-left: 20px;
    margin-right: 0;
    margin-top: 7px;
  }

  body.dark-mode .c.front h1 {
    color: #fff;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 7px;
  }

  body.dark-mode .c.front h1 .dates {
    background-color: #1b1b1c;
    border-radius: 5px;
    color: #fff;
    font-size: 0.8em;
    line-height: 1em;
    padding: 0 5px;
  }

  body.dark-mode .c.front .description {
    display: relative;
    font-size: 13px;
    font-weight: 400;
    margin-left: -20px !important;
    margin-top: 5px;
    white-space: pre-wrap;
    width: 91%;
  }

  body.dark-mode .c.front .description .nestedh3 {
    background: none;
    color: #fff;
    font-size: 13px;
    font-weight: 900;
  }
}

@media (max-width: 694px) and (min-width: 1100px) {
  body.dark-mode .c.front .description {
    font-size: 0.8em;
  }
}

@media (max-width: 694px) {
  body.dark-mode .c.back h1 {
    bottom: -15px;
    color: #fff;
    display: absolute;
    font-size: 30px;
    font-weight: 600 !important;
    left: 40px;
    position: fixed;
  }

  body.dark-mode .c.back h1 img {
    bottom: 10px;
    height: 40px;
    left: 10px;
    position: fixed;
  }

  body.dark-mode .c.back .dates {
    background-color: #1b1b1c;
    border-radius: 10px;
    bottom: 12px;
    color: #fff;
    font-size: 10px;
    line-height: 20px;
    margin-left: 5px;
    padding: 0 5px;
    position: fixed;
  }
}

body.dark-mode .linksEvent {
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 20px;
  width: 8%;
}

body.dark-mode .linksEvent .fbEvent {
  background-color: #1778f2;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.dark-mode .linksEvent .fbEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .linksEvent .instaEvent {
  background: #f09433;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.dark-mode .linksEvent .instaEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

body.dark-mode .linksEvent .siteEvent {
  background-color: #20c997;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #fff;
  justify-content: center;
  padding: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
  pointer-events: auto !important;
  text-align: center;
  width: 100%;
  z-index: 10000000000;
}

body.dark-mode .linksEvent .siteEvent:hover {
  -webkit-filter: saturate(1.5);
  filter: saturate(1.5);
}

@media (min-width: 694px) {
  body.dark-mode .c.back h1 {
    bottom: -20px;
    color: #fff;
    display: absolute;
    font-size: 40px;
    font-weight: 600 !important;
    left: 60px;
    position: fixed;
  }

  body.dark-mode .c.back h1 img {
    bottom: 10px;
    height: 64px;
    left: 10px;
    position: fixed;
  }

  body.dark-mode .c.back .dates {
    background-color: #1b1b1c;
    border-radius: 10px;
    bottom: 15px;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    margin-left: 10px;
    padding: 0 5px;
    position: fixed;
  }

  body.dark-mode .rodal-h1 {
    color: #fff !important;
  }

  body.dark-mode .rodal-imp {
    backdrop-filter: blur(10px);
    background: rgba(67, 84, 182, 0.16);
    overflow-y: scroll;
  }

  body.dark-mode .darkIcon {
    padding-left: 10px;
    padding-top: 20px;
  }
}

body.dark-mode .darkIcon {
  padding-left: 10px;
  padding-top: 20px;
}

body.dark-mode .counterHR {
  border: 1px solid #1b1b1c;
}

.rodal-imp {
  backdrop-filter: blur(10px);
  background: rgba(67, 84, 182, 0.16);
}
